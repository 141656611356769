import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function About() {
  return (
    <Layout>
      <div id="about">
        <SEO title="About" />
        <div className="container m-b-lg">
          <div>
            <div>
              <h2>About</h2>
              <p>
                <b>congress.cards</b> is a site that allows you to send real
                paper mail to your elected officials, while also raising funds
                for the causes you care about.
              </p>
              <p>
                We strive for full transparency, so you can see exactly how many
                cards have been sent, and how much $ has been donated.
              </p>
              <hr />
              <h3>F.A.Q.</h3>
              <ul>
                <li>
                  <h4>Where does the $2 go?</h4>
                  <p>
                    Transparency is part of the congress.cards mission
                    statement. We didn’t want to be one of those groups who say
                    “some proceeds donated...” We all know what that means.
                    That’s why,{" "}
                    <b>
                      for every card sent, we transparently donate $1 to the
                      specified cause/organization.
                    </b>
                  </p>
                  <h4>$2 Breakdown</h4>
                  <ul className="cost-breakdown">
                    <li> $1 donated directly to the specified organization.</li>
                    <li>
                      {" "}
                      $1 goes to operational costs. It cost $0.80-$.90 to
                      process payment, print and mail a postcard. Anything
                      remaining goes to operational costs.
                    </li>
                    <li>There will never be any 'profit' taken out.</li>
                  </ul>
                </li>
                <li>
                  <h4>How do I know my card was sent?</h4>
                  <p>
                    We will send you email updates as your card is in transit.
                    We are currently unable to confirm delivery, but it’s
                    something we hope to add in the future.
                  </p>
                </li>
                <li>
                  <h4>Will you be adding more issues?</h4>
                  <p>
                    That's‌ ‌the‌ ‌idea!‌ ‌If‌ ‌you‌ ‌have‌ ‌specific‌ ‌issues‌
                    ‌to‌ ‌suggest,‌ ‌feel‌ ‌free‌ ‌to‌ ‌email‌ ‌us.‌ ‌If‌ ‌you‌
                    ‌have‌ ‌artwork‌ ‌ already‌ ‌approved‌ ‌to‌ ‌be‌ ‌used‌ ‌on‌
                    ‌postcards,‌ ‌that‌ ‌would‌ ‌make‌ ‌it‌ ‌much‌ ‌easier‌
                    ‌for‌ ‌us‌ ‌to‌ ‌add‌ ‌a‌ ‌new‌ ‌ campaign.‌ ‌
                  </p>
                </li>
                <li>
                  <h4>Can I submit artwork to be used in postcards?</h4>
                  <p>
                    Yes! Just email
                    <a href="mailto:info@congress.cards" target="_blank">
                      {" "}
                      info@congress.cards{" "}
                    </a>
                    with your artwork, or to chat through an idea. Postcard
                    ratio is 3:2 or 2:3.
                  </p>
                </li>
              </ul>
              <hr />
              <h3>Contact Us</h3>
              <p>
                If you have questions, or would like to contribute artwork for
                future postcards, you can get in contact with us at{" "}
                <a href="mailto:info@congress.cards" target="_blank">
                  info@congress.cards
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
